import { Auth } from 'aws-amplify';
import getConfig from 'next/config';
// import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';
import passwordGenerator from 'generate-password';

import Sentry from './sentry';
import getApolloClient from './getApollo';
import logger from './logger';
import ConvertAnonymousUser from './ANON_USER.graphql';
import UPDATE_SOURCE_USER from './UPDATE_SOURCE_USER.graphql';
import { ANONYMOUS_SLUG } from '@/constants';
import { REFERRER_URL, REFERRER_VAL } from './context';
import { getUPFURI } from './getUpf';
const { publicRuntimeConfig } = getConfig();
const { log, error } = logger('helpers:user');
const ANONYMOUS_USER_KEY = 'anonymous';
const {
  APP_ID,
  REGION_ID,
} = publicRuntimeConfig;
const isBrowser = typeof window !== 'undefined';

export const generate = {
  email: () => `${uuidv4()}@example.com`,
  uuid: uuidv4(),
  password: () => passwordGenerator.generate({
    length: 12,
    numbers: true,
    uppercase: true,
    strict: true,
  }),
};
export const getReferrer = () => {
  if (isBrowser) {
    const { referrer } = document;
    const referrerString = referrer.substring(0, referrer.indexOf('?') - 1);
    return referrerString !== '' ? referrerString : referrer;
  }
  return '';
};

export const setSlugInspear = async (slug: string) => {
  const spearSlug = await localStorage.getItem(ANONYMOUS_SLUG);
  if (!spearSlug) {
    await localStorage.setItem(ANONYMOUS_SLUG, JSON.stringify({ slug }));
  }
};
export const anonymous = {
  create: async (data?: any) => {
    const { signOut = false } = data || {};
    const user = await Auth.currentUserInfo();
    if (user && !signOut) {
      log('User is already logged in.', user);
      return {};
    }

    log('Creating anonymous user...');
    const emailString = generate.email();
    const passwordString = generate.password();

    // console.log('emailString : ', emailString);
    // console.log('password : ', passwordString);

    try {
      const result = await Auth.signUp({
        username: emailString,
        password: passwordString,
        attributes: {
          'custom:appId': APP_ID,
          'custom:regionId': REGION_ID,
          'custom:isAnonymous': true.toString(),
          'custom:source': getReferrer(),
          'custom:provider': '',
          email: emailString,
        },
      });
      if (result) {
        const userInfo = {
          email: emailString,
          password: passwordString,
        };

        const { userSub } = result;
        log('Anonymous user created.', result);
        localStorage.setItem(ANONYMOUS_USER_KEY, JSON.stringify(userInfo));
        Sentry.setUser({
          email: emailString,
          isAnonymous: true,
          id: userSub,
        });
        return userInfo;
      }
      return {};
    } catch (e) {
      error('anonymous:create', e);
      return null;
    }
  },
  /**
   * Convert anonymous user, If password is supplied.
   * @param email
   * @param password?
   */
  update: async (email: string, password?: string, userAgreementConfirmed?: boolean) => {
    console.log('update')
    const user = await Auth.currentAuthenticatedUser();
    const client = await getApolloClient();
    if (!user) {
      error('anonymous:update', 'Anonymous user not exist.');
      return {};
    }
    try {
      const userData = JSON.parse(localStorage.getItem(ANONYMOUS_USER_KEY) || '{}');
      const userInfo = {
        email,
        password: password || userData.password,
      };

      const userAgreement = {
        userAgreementConfirmed,
      };

      const result = await client.mutate({
        mutation: ConvertAnonymousUser,
        variables: userAgreementConfirmed ? { ...userInfo, ...userAgreement } : userInfo,
      });
      
      if (password) {
        localStorage.removeItem(ANONYMOUS_USER_KEY);
        if (result) {
          await Auth.updateUserAttributes(user, {
            'custom:isAnonymous': false.toString(),
          });
        }
      }

      console.log('result:2', result);

      const spearSlug = await localStorage.getItem(ANONYMOUS_SLUG);
      console.log('spearSlug', spearSlug);
      const spearSlugObj = JSON.parse(spearSlug as string);
      console.log('spearSlugObj', spearSlugObj);
      const { isUpdateSource } = spearSlugObj;
      console.log('isUpdateSource', isUpdateSource);
      const referLocal = await localStorage.getItem(REFERRER_URL);
      console.log('referLocal', referLocal);
      if (referLocal?.length !== 0 && referLocal) {
        if (userAgreementConfirmed && !isUpdateSource) {
          const referVal = await localStorage.getItem(REFERRER_VAL);
          console.log('referVal', referVal);
          const referValObj = JSON.parse(String(referVal));
          console.log('referValObj', referValObj);
          const { upf = null, amount = 0, asPath = '' } = referValObj || {};
          console.log('upf', encodeURIComponent(upf));
          console.log('amount', amount);
          console.log('username', String(getUPFURI(user.username).split('?upf=')[1]).trim());
          const check = (String(getUPFURI(user.username).split('?upf=')[1]).trim() !== String(encodeURIComponent(upf)).trim());
          console.log('check', check);
          const exactUpf = upf ? (check ? `:${asPath !== '' ? asPath : `upf=${upf}`}` : '') : (asPath !== '' ? `:${asPath}` : '');
          console.log('exactUpf', exactUpf);
          const _result = await client.mutate({
            mutation: UPDATE_SOURCE_USER,
            variables: {
              source: `${referLocal}${exactUpf}`,
            },
            fetchPolicy:'cache-and-network'
          });
          console.log('_result', _result);
          if (_result) {
            const resposeResult = JSON.stringify({ isUpdateSource: referLocal !== '', slug: referLocal });
            console.log('resposeResult', resposeResult);
            console.log('ANONYMOUS_SLUG', ANONYMOUS_SLUG);
            localStorage.setItem(ANONYMOUS_SLUG, resposeResult);
          }
        }
      }
      Sentry.setUser({
        email,
        isAnonymous: false,
        username: user.username,
      });
      return userInfo;
    } catch (e) {
      const { graphQLErrors = [] } = e as any;
      const [{ message = '' } = {}] = graphQLErrors;
      error('anonymous:update', message);
      error('anonymous:update', e);
      return { error: message };
    }
  },
  verify: (code: string) => Auth.verifyCurrentUserAttributeSubmit('email', code),
};

log('anonymous: ', anonymous);
let isLoginProgress = false;
const user = {
  signUp: async (email: string, password: string, provider?: string) => {
    console.log('signUp')
    const cognitoUser = await Auth.signUp({
      username: email,
      password,
      attributes: {
        'custom:appId': APP_ID,
        'custom:regionId': REGION_ID,
        'custom:isAnonymous': false.toString(),
        'custom:source': getReferrer(),
        'custom:provider': provider || '',
        email,
      },
    });
    console.log('Signup result:', cognitoUser);
    return cognitoUser;
  },

  login: async (email: string, password: string) => {
    console.log('login')
    if (isLoginProgress) return
    isLoginProgress = true
    try{
      const cognitoUser = await Auth.signIn(email, password)
      console.log({ cognitoUser });
      Sentry.setUser({
        id: cognitoUser.sub,
        email: cognitoUser.email,
      });
      isLoginProgress = false;
      return cognitoUser;
    }catch(e){
      isLoginProgress = false;
      throw e
    }
  },
  logout: async () => Auth.signOut(),
  get: async () => Auth.currentUserInfo(),
};

export default user;
