import styled, { keyframes } from 'styled-components';
import React from 'react';
import {
  Logo, TopSide, Wrapper,
} from './loading.style';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrap = styled.div`
 display: block;
 text-align: center;
  padding: 20px 0;
`;

const Load = styled.span`
margin:0px;
padding:0px;
 animation: ${rotate} 0.6s linear infinite;
 font-size: ${({ $size }:any) => $size}px;
 display: inline-block;
 color: ${({ theme, $light }:any) => ($light ? theme.text.primary : theme.text.secondary)};
`;
const Linkwrapper=styled.div`
padding:0px;
 display: block;
 text-align: center;
`
const Linkloader=styled.span`
margin:0px;
padding:0px;
 animation: ${rotate} 0.6s linear infinite;
 font-size: ${({ $size }:any) => $size}px;
 display: inline-block;
 margin-left:10px;
 color:${({ theme: { shades } }:any) => shades[0]};
`;

const LogDonationLoader=styled.span`
margin:0px;
padding:0px;
 animation: ${rotate} 0.6s linear infinite;
 font-size: ${({ $size }:any) => $size}px;
 display: inline-block;
 float: right;
 margin-right: 42px;
 margin-top: -14px;
 color:${({ theme: { shades } }:any) => shades[0]};
`;


export const Loading = ({ size, light }:any) => (
  <Wrap>
    <Load $size={size} $light={light} className="icon-spinner" />
  </Wrap>
);
//@ts-ignore
export const Linkloading = ({ size, light }:any) => (
  <Linkwrapper>
    <Linkloader $size={size} $light={light} className="icon-spinner" />
  </Linkwrapper>
);

export const LogDonationLoading = ({ size, light }:any) => (
  <Linkwrapper>
    <LogDonationLoader $size={size} $light={light} className="icon-spinner" />
  </Linkwrapper>
);

export const Pageloader = () => <Loading size={30} light />;

export const PageLoader = () => (
  <Wrapper>
    <TopSide>
      <Logo src="/logo/logo-512.png" />
    </TopSide>
    <Loading size={50} light />
  </Wrapper>
);

export default Loading;
