import styled, { css } from "styled-components";
import Button from "../../../ui/button";

interface INavProps {
  theme?: any;
  size?: number;
  selected?: boolean;
}

export const MenuContainer = styled.div`
  ${({ theme: { shades } }: any) => `
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 54px;
    background-color: #ffffff;
    border-top: 1px solid ${shades && shades[2]};
  `}
`;

export const ButtonMenu = styled.div`
  ${({ theme: { primary, shades }, size, selected }: INavProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 200px;
    font-size: ${size && size}px;
    color: ${selected ? primary : shades && shades[3]};
    & {
      :before {
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        content: "";
        width: 0;
        height: 3px;
        transform: translate(-50%, 0);
        background-color: ${primary};
        opacity: 0;
        transition: all 0.5s cubic-bezier(0.83, 0, 0.17, 1);
        ${selected &&
        css`
          width: 50%;
          opacity: 1;
        `};
      }
    }
  `}
`;

export const NotificationMenu = styled.div`
  ${({ theme: { danger } }: any) => `
    display: block;
    top: -20px;
    right: 40%;
    transform: translate(-20%,-150%);
    width: 8px;
    height: 8px;
    background-color: ${danger};
    border-radius: 5px;
  `}
`;
export const Donation_Icon = styled.span`
  ${({ theme: { primary, shades } }: any) => `
width: 50px;
height: 50px;
display: flex;
margin-top: -29px;
text-align: center;
-webkit-justify-content: space-around !important;
font-size: 40px;
position: absolute;
z-index:999;
background: ${primary};
color:${shades[0]};
border-radius:50%;
&.close{
  background:${shades[8]};
  font-size:30px;
  text-align:center;
  padding:4px;
}

`}
`;

export const DonationGoalContent = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-evenly;
  align-items: center;
  
`;
export const LogDonation = styled.span`
  width: 50%;
  font-weight: 600;
  padding: 2vh;
  font-size:14px;
`;
export const MakeDonation = styled.span`
  width: 50%;
  font-weight: 600;
  font-size:14px;
  padding: 2vh;
`;
export const MiddleLine = styled.div`
  ${({ theme: { shades } }: any) => `
   width: 2px;
    height: 95px;
    background: ${shades[8]};
    margin-top: -46px;
`}
`;
export const Open = styled.button`
${({ theme: {primary,shades},disabled }: any) => `
  display: flex;
  width: 60px;
  height: 60px;
  margin-bottom: 50px;
  border-radius: 50%;
  padding: 20px;
  font-size: 24px;
  background:${disabled ? shades[13]:primary};
  color:${shades[0]};
  z-index: 99;
  justify-content: space-evenly;
  align-items: center;
  border:none;
  box-shadow: 0px 3px 11px rgb(152 147 147);
`}`;

export const Close = styled.span`
${({ theme: {shades} }: any) => `
  display: flex;
  width: 60px;
  height: 60px;
  margin-bottom: 50px;
  border-radius: 50%;
  background: red;
  padding: 20px;
  font-size: 24px;
  background: ${shades[8]};
  color:${shades[0]};
  z-index: 9999;
  justify-content: space-evenly;
  align-items: center;
`}`;
