export const DEEP_ROUTE = (route: string) => ({
  DETAILS: `${route}/details`,
  FAVOURITE: `${route}/favourites`,
  BANK_CARDS: `${route}/bankcards`,
  JUST_GIVE: `${route}/justgive`,
  DONATION_GOALS:`${route}/donation_goals`,
  JUSTGIVE_SUCCESS: `${route}/justgive/success`,
  CHANGE_PASSWORD: `${route}/changePassword`,
  DEFAULT: "/profile",
});

export const SUB_ROUTE = (main: string, sub: string) => `/${main}/${sub}`;

export const ROUTE = {
  WELCOME: "/welcome",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOTTEN_PASSWORD:'/forgottenpassword',
  PROFILE_CHARITY: SUB_ROUTE("profile", "charity"),
  PAYMENT_DETAILS: SUB_ROUTE("payment", "details"),
  HOME: "/",
  HOMEPAGE:'/home',
  LOG_DONATE: "/log",
  DONATE: "/donate",
  LOG_DONATION:'/donate/logDonation',
  EDIT_LOG_DONATION:'/donate/logDonation/editLogDonation',
  LOGDONATION_SUCCESS:'/donate/logDonation/logDonationsuccess',
  MAKE_DONATION:'/donate/makedonation',
  DONATE_SUCCESS: SUB_ROUTE("donate", "success"),
  ABOUT: "/about",
  BANK_CARD: SUB_ROUTE("payment", "bankcards"),
  PROFILE_USER: DEEP_ROUTE(SUB_ROUTE("profile", "user")),
  TAX: SUB_ROUTE("payment", "tax"),
  BROWSE: "/browse",
  CAMPAIGN_TAGS: "/campaigntag",
};
// Only dev mode
export const DONATION_CALLBACK = "https://localhost:3000/donate/success";

export const SOCIAL_DATA_STACK = "SOCIAL_DATA_STACK";
export const FOR_SOCIAL_FAVOUITE = "FOR_SOCIAL_FAVOUITE";
export const ANONYMOUS_SLUG = "ANONYMOUS_SLUG";
export const LAST_SOURCE_UPDATE = "LAST_SOURCE_UPDATE";
export const ALERT_TNC = "Please agree to our Terms and Conditions";
export const FEED_SOURCES = {
  JUST_GIVING_DONATIONS: "FEED#JUSTGIVING#DONATIONS",
  AFD_POSTCODES: "FEED#AFD#POSTCODES",
  CHARITIES_TRUST_CHARITIES: "FEED#CT#CHARITIES",
};

export interface INavigation {
  /**
   * Parent route
   */
  main: string;
  /**
   * Icon menu
   */
  icon: string;
  /**
   * Sub routes of parent route
   */
  sub: string[];
  /**
   * Selected route
   */
  selected: boolean;
  /**
   * Notify profile expried cards
   */
  notify: boolean;
}

export const NAVIGATIONS: INavigation[] = [
  {
    main: ROUTE.HOMEPAGE,
    sub: [
      
    ],
    icon: "icon-home",
    selected: true,
    notify: false,
  },

  {
    main: ROUTE.HOME,
    sub: [
      `${ROUTE.PROFILE_CHARITY}/[charityId]`,
      `${ROUTE.DONATE}/[charityId]`,
      ROUTE.PAYMENT_DETAILS,
      ROUTE.DONATE_SUCCESS,
      ROUTE.CAMPAIGN_TAGS,
    ],
    icon: "icon-search",
    selected: true,
    notify: false,
  },
  {
    main: ROUTE.LOG_DONATE,
    sub: [],
    icon: "",
    selected: true,
    notify: false,
  },
  {
    main: ROUTE.TAX,
    sub: ["/payment/tax/details/[taxId]"],
    icon: "icon-tax",
    selected: false,
    notify: false,
  },
  {
    main: ROUTE.PROFILE_USER.DEFAULT,
    sub: [
      ROUTE.PROFILE_USER.FAVOURITE,
      ROUTE.PROFILE_USER.DETAILS,
      ROUTE.PROFILE_USER.BANK_CARDS,
    ],
    icon: "icon-account",
    selected: false,
    notify: false,
  },
];

export const ACTION = {
  FAVOURITE: "Create your WeGive account to save a charity to your favourites.",
  HISTORY: "Create your WeGive account to see your donation history.",
  MAKE_DONATION:
    "If you would like to keep track of your donations and save your details, sign up to create a WeGive account:",
};

export const DONATED_LIST = "donatedList";
export const DONATE_REFER = "donationReference";

export const paymentCardType = {
  VISA: "/images/svg/cards/visa.svg",
  MC: "/images/svg/cards/mastercard.svg",
  MCDEBIT: "/images/svg/cards/mastercard.svg",
  AE: "/images/svg/cards/american.svg",
  DELTA: "/images/svg/cards/delta.svg",
};

export const eventTypes = {
  TAG: "tech_wegive_TAG_get",
  CMP: "tech_wegive_CMP_get",
  CMP_POST: "tech_wegive_CMP_post_propose",
};
