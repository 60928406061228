import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const {
  APP_ID,
  REGION_ID,
} = publicRuntimeConfig;

export const getUPFURI = (username: string) => `?upf=${encodeURIComponent(`UPF#${REGION_ID}#${APP_ID}#${username}`)}`;

export const getUPFID = (username: string) => `${encodeURIComponent(`UPF#${REGION_ID}#${APP_ID}#${username}`)}`;