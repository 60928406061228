import { Auth, Hub } from 'aws-amplify';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';

import getConfig from 'next/config';
import { error } from './logger';
import user, { anonymous, getReferrer, setSlugInspear } from './user';
import { PageLoader } from '../components/ui/loading';
import PWAInstall from '../components/ui/pwa/install';
import { getUPFURI } from './getUpf';
// import getApolloClient from './getApollo';
// import UPDATE_SOURCE_USER from './UPDATE_SOURCE_USER.graphql';
// import { ANONYMOUS_SLUG } from '../constants';
// import SEOPAGE from '../pages/seo';

interface IUpdateContext {
  page: string;
  data: any;
}

interface IAppContext {
  [key: string]: any;
}

const log = error('context');
const initial: IAppContext = {};
const { publicRuntimeConfig } = getConfig();

const {
  APP_ID,
  REGION_ID,
} = publicRuntimeConfig;
const NEW_DEVICE_KEY = 'IS_FIRST_LOAD';
export const REFERRER_URL = 'REFERRER_URL';
export const REFERRER_VAL = 'REFERRER_VAL';
export const REFERRER_VAL_UPDATED = 'REFERRER_VAL_UPDATED';
export const Context = createContext(initial);

const checkIfNewDevice = () => {
  if (typeof window !== 'undefined') {
    const isNew = localStorage.getItem(NEW_DEVICE_KEY);
    if (!isNew) return true;
    return isNew === 'true';
  }
  return false;
};

let originalPathname = '';
const AppProvider = ({ children }:any) => {
  const router = useRouter();
  const [state, setState] = useState(initial);
  const [isSignup, setSignUp] = useState(false);
  const [isPasswordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [isPassword,setIsPassword]=useState(false);
  const [isLoadedPage, setLoadedPage] = useState(false);
  const [isSourceUpdate, setSourceUpdate] = useState(false);

  

  /**
   * Context will keep full state of application
   * by there page name.
   * To update context please pass page name and its data related to that page name.
   * @param update
   */
  const updateContext = (update: IUpdateContext) => {
    const { page, data } = update;
    setState(((prevState) => {
      const prevPageData = prevState[page];
      return {
        ...prevState,
        [page]: { ...prevPageData, ...data },
      };
    }));
  };

  /**
   * Function will return bool on user is valid and not anonymous
   * App Context will contain auth:{ isValidUser:Boolean }
   * @param userInfo
   */
  const getIsValidUser = (userInfo:{ attributes: any; username: string }) => {
    const { attributes = {}, username } = userInfo || {};
    let isNotAnonymous = attributes['custom:isAnonymous'] === 'false';
    if (attributes['custom:isAnonymous'] === undefined) {
      isNotAnonymous = true;
    }
    return (isNotAnonymous && !!username);
  };

  const getSource = () => {
    const isReferrer = (getReferrer().indexOf('ngrok.io') === -1 && getReferrer().indexOf('wegive.tech') === -1);
    console.log('isReferrer: >>', isReferrer);
    const isDonated = (getReferrer().indexOf('ctdonate.org') === -1 ? isReferrer : false);
    console.log('isDonated: >>', isDonated);
    const isFacebookSignin = (getReferrer().indexOf('facebook.com/login.ph') === -1 ? isDonated : false);
    console.log('isFacebookSignin: >>', isFacebookSignin);
    const isGooleSignin = (getReferrer().indexOf('accounts.google') === -1 ? isFacebookSignin : false);
    console.log('isGooleSignin: >>', isGooleSignin);
    return isGooleSignin ? { source: getReferrer(), isUpdate: isSourceUpdate } : { source: getReferrer(), isUpdate: true };
  }

  const setSource = (value: boolean) => {
    setSourceUpdate(value);
  }

  const signUp = async (email: string, password: string, userAgreementConfirmed: boolean) => {
    try {
      const user = await anonymous.update(email, password, userAgreementConfirmed);
      setSignUp(true);
      return user;
    } catch (e) {
      log('signUp', e);
      return null;
    }
  };


  const contextSignupSuccess = () => {
    console.log('contextSignupSuccess');
    setSignUp(false);
    setIsPassword(true)
  };
  const contextPasswordChangeSuccess=()=>{
    setPasswordChangeSuccess(true)
  }
  const PasswordChange=()=>{
    setPasswordChangeSuccess(false)
  }

  const login = async (email: string, password: string) => {
    try {
      return await user.login(email, password);
    } catch (e) {
      log('login', e);
      throw e;
    }
  };

  const logout = async () => {
    try {
      await user.logout();
    } catch (e) {
      log('logout', e);
      throw e;
    }
  };

  const setWelcomeScreen = (flag: boolean) => {
    localStorage.setItem(NEW_DEVICE_KEY, flag.toString());
    setSlugInspear(getReferrer());
    localStorage.setItem(REFERRER_URL, getReferrer());
    const { username } = state.auth;
    const upfURI = getUPFURI(username);
    const combineURL = `${originalPathname.split('?')[0]}${upfURI}`
    if (!flag && originalPathname) {
      const campaignId =originalPathname.includes('cmpId')
      if(campaignId){
        window.location.href = originalPathname;
      }
      else{
        window.location.href = combineURL;
      }
      return true;
    }
    if (!flag && !originalPathname) {
      return router.push(`/${upfURI}`);
    }
    return false;
  };

  const updateAuth = async (data?:any) => {
    const userInfo:any = await Auth.currentUserInfo();
    if (data) {
      const { username = '' } = data || {};
      const chunks = username.split('_');
      let provider = '';

      if (chunks.length) {
        // eslint-disable-next-line prefer-destructuring
        provider = chunks[0];
      }
      // console.log('provider : ', provider);
      if (provider === 'Facebook' || provider === 'Google') {
        const userData = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(userData, {
          'custom:isAnonymous': false.toString(),
          'custom:appId': APP_ID,
          'custom:regionId': REGION_ID,
          'custom:source': '',
          'custom:provider': provider,
        });
      }
    }
    setState(((prevState) => ({
      ...prevState,
      auth: {
        ...userInfo,
        isValidUser: getIsValidUser(userInfo),
      },
    })));
  };



  useEffect(() => {
    // Injecting auth object in context if user logged in
    if (!state.auth) {
      setLoadedPage(false);
      updateAuth();
      if (checkIfNewDevice() && router.pathname !== '/welcome') {
        setLoadedPage(false);
        setTimeout(() => {
          originalPathname = window.location.href;
          // console.log('GO WELCOME PAGE');
          setLoadedPage(true);
          router.push('/welcome');
        }, 500);
      }
    } else {
      setLoadedPage(true);
    }
  }, [state.auth]);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      // console.log('data : ', data);
      if (payload.event === 'signIn') {
        // console.log('user signed in');
        updateAuth(payload.data);
      }
      if (payload.event === 'signOut') {
        // console.log('user signed out');
        setState(((prevState) => ({
          ...prevState,
          auth: undefined,
        })));
      }
    });
  }, []);

  return (
    <Context.Provider value={{
      context: state,
      updateContext,
      setWelcomeScreen,
      signUp,
      login,
      logout,
      isPassword,
      isSignup,
      contextSignupSuccess,
      contextPasswordChangeSuccess,
      PasswordChange,
      isPasswordChangeSuccess,
      getSource,
      setSource
    }}
    >
      {!isLoadedPage && <PageLoader />}
      {children}
      <PWAInstall isNewDevice={checkIfNewDevice()} details={state.donateSuccess} />
    </Context.Provider>
  );
};

export default AppProvider;
