import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  Actions,
  Text,
  StyledButton,
  ShareIcon,
  ActionsIOS,
  CloseButton,
} from './pwa.style';

export const PWA_STATUS_KEY = 'PWA_STATUS';

interface IPWAInstallProps {
  isNewDevice: boolean
  details: any
}

const PWAInstall = ({ isNewDevice = true, details = {} }: IPWAInstallProps) => {
  const initPWA:any = null;
  const [pwa, setPWA] = useState(initPWA);
  const [show, setShow] = useState(false);
  const [isFadOut, setFadOut] = useState(false);
  const [device, setDevice] = useState('');

  const isiOS = () => [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  // PWA custom install
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      if (!isNewDevice) {
        setPWA(e);
      }
    });
    window.addEventListener('appinstalled', () => {
      localStorage.setItem(PWA_STATUS_KEY, 'accepted');
      setShow(false);
    });
  }, [isNewDevice]);

  useEffect(() => {
    const isIos = isiOS();
    if (isIos) {
      setDevice('ios');
    }
    if (details && (pwa || isIos)) {
      const { id } = details;
      const pwaStatus = localStorage.getItem(PWA_STATUS_KEY);
      if (id && pwaStatus !== 'denied') {
        // setTimeout(() => {
        //   setShow(true);
        // }, 5000);
        setFadOut(false);
        setShow(true);
      }
    }
  }, [details, pwa]);

  const onCancel = () => {
    setFadOut(true);
    setTimeout(() => {
      localStorage.setItem(PWA_STATUS_KEY, 'denied');
      setShow(false);
    }, 500);
  };

  const openShareIOS = async () => {
    const nav:any = window.navigator;
    if (nav && nav.share) {
      const { origin } = window.location;
      const data = {
        title: 'Install WeGive',
        text: 'Install App | WeGive',
        url: origin,
      };
      await nav.share(data);
      localStorage.setItem(PWA_STATUS_KEY, 'accepted');
      setShow(false);
    }
  };

  const onInstall = async () => {
    if (device === 'ios') {
      openShareIOS();
    }
    if (pwa && pwa.prompt) {
      pwa.prompt();
      const choiceResult = await pwa.userChoice;
      if (choiceResult.outcome === 'accepted') {
        localStorage.setItem(PWA_STATUS_KEY, 'accepted');
        window.location.reload();
      } else {
        onCancel();
      }
    }
  };

  if (!show) return null;
  return (
    <Wrapper $isIOS={isiOS()} isClose={isFadOut}>
      <Text>Keep donating, even if you are offline!</Text>
      {!isiOS() ?
        <Actions>
          <StyledButton type="primary" onClick={onCancel}>Not now</StyledButton>
          <StyledButton type="secondary" $isInstall onClick={onInstall}>Install</StyledButton>
        </Actions> :
        <ActionsIOS>
          <Text>Add to Home Screen by clicking on<ShareIcon /></Text>
          <CloseButton onClick={onCancel} />
        </ActionsIOS>
      }
    </Wrapper>
  );
};

export default PWAInstall;
