import React, { useContext, useEffect, useState } from 'react';
import nookies from 'nookies';

import {
  Wrapper,
  LearnMoreLink,
  AcceptButton,
  Text,
} from './cookie.style';
import { Context } from '../../../helpers/context';

export const COOKIE_KEY = 'cookiePolicy';
const LIVED = 87660;

interface ICookieProps {
  onAccept?: () => any;
  learnMoreLink?: string;
  [key:string]:any;
}

export default ({ onAccept, learnMoreLink, ...rest }:ICookieProps) => {
  const [accept, setAccept] = useState(true);
  const { updateContext } = useContext(Context);
  const setCookie = () => {
    const maxAge = (3600 * 1000) * LIVED;
    nookies.set(null, COOKIE_KEY, new Date().getTime().toString(), {
      maxAge,
      path: '/',
    });

    updateContext({
      page: 'cookies',
      data: {
        cookieAccepted: true,
      },
    });
  };

  const onAcceptCookie = () => {
    setCookie();
    setAccept(true);
  };

  useEffect(() => {
    const cookies = nookies.get(null);
    if (!cookies[COOKIE_KEY]) {
      setAccept(false);
    } else {
      // refresh existing cookie
      setCookie();
    }
  }, []);

  if (accept) return null;

  return (
    <Wrapper {...rest}>
      <Text>
        We store some user data to bring you the finest app experience.
        If you continue, we’ll assume you are ok with this.
      </Text>
      <LearnMoreLink href={learnMoreLink} target="_blank" rel="noreferrer">Learn more</LearnMoreLink>
      <AcceptButton data-cy="cookie" type="default" onClick={onAccept || onAcceptCookie}>Got it</AcceptButton>
    </Wrapper>
  );
};
