import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme: { primary } }:any) => primary};
  text-align: center;
  height: 100%;
  color: ${({ theme: { text } }:any) => text.primary};
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const TopSide = styled.div`
  position: relative;
  display: block;
  height: 60%;
`;

export const Logo = styled.img`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  width: 50%;
`;
