import { Amplify, Auth } from 'aws-amplify';
import { AUTH_TYPE, AuthLink } from 'aws-appsync-auth-link';
import getConfig from 'next/config';
import { lazy } from 'apollo-link-lazy';
import Router from 'next/router';

import { error } from './logger';
import awsConfig from './aws.config';
import { isSocial } from './isSocial';
import { useContext } from 'react';
import { Context } from '../helpers/context';
enum CognitoHostedUIIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Amazon = 'LoginWithAmazon',
  Apple = 'SignInWithApple'
}

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const log = error('appSync');

const {
  APP_AWS_REGION = '',
  GRAPHQL_URI = '',
} = publicRuntimeConfig;

const {
  APP_AWS_API_KEY,
} = serverRuntimeConfig;

export const configureAmplify = async () => Amplify.configure(awsConfig);

const signInAnonymousUser = async () => {
  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    let result;
    if (typeof window !== 'undefined') {
      result = localStorage.getItem('anonymous');
    }

    if (result) {
      let user = JSON.parse(result);
      if (user && user.email) {
        await Auth.signIn(user.email, user.password);
      }
    }
  }
};
const refreshTokens = async () => {
  try {
    await Auth.currentSession();
  } catch (e:any) {
    if (e.code === 'NotAuthorizedException') {
      await signInAnonymousUser();
    }
  }
};
const onSignToSocialMedia = (provider: string) => {
  Amplify.Auth.federatedSignIn({ provider });
};
const manualLogin = () => {
  localStorage.clear();
  sessionStorage.clear();
  Router.push('/signin');
}


/**
 * @description Return AWS auth type
 * If token preset then returns AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
 * If server and no token then returns AUTH_TYPE.API_KEY
 * @return Promise<{type, jwtToken?, apiKey?}>
 */
const getAuthWithType = async (useAPIKey = false) => {
  let token = '';
  try {
    token = (await Amplify.Auth.currentSession()).getIdToken().getJwtToken();
    let user = await Auth.currentUserInfo();
    if (user.attributes["custom:isAnonymous"] == "true") {
      if (typeof window !== 'undefined') {
        localStorage.setItem("isUserAnonymous", "true")
      }
    } else {
      if (typeof window !== 'undefined') {
        localStorage.setItem("isUserAnonymous", "false")
      }
    }
  } catch (e) {
    // log('getAuthWithType', e);
    let isUserAnonymous;
    if (typeof window !== 'undefined') {
      isUserAnonymous = localStorage.getItem("isUserAnonymous");
    }
   
    if (isUserAnonymous == "true" || !isUserAnonymous) {
      await signInAnonymousUser();
      token = (await Amplify.Auth.currentSession()).getIdToken().getJwtToken();
    } else {
      const { context: { auth } } = useContext(Context);
      if (isSocial(auth)) {
        const provider = auth.username.search('Google') > -1 ? CognitoHostedUIIdentityProvider.Google
          : auth.username.search('Facebook') > -1 ? CognitoHostedUIIdentityProvider.Facebook : null;
        if (provider !== null) {
          onSignToSocialMedia(provider)
        } else {
          manualLogin();
        }
      }
      else {
        manualLogin();
      }
    }


  }

  if (token) {
    return {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: Promise.resolve(token),
    };
  }

  if (useAPIKey && APP_AWS_API_KEY) {
    return {
      type: AUTH_TYPE.API_KEY,
      apiKey: APP_AWS_API_KEY,
    };
  }
  return {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: '',
  };
};
export const authLink = (useAPIKey?: boolean) => lazy(async () => {
  await refreshTokens(); 
  const auth = await getAuthWithType(useAPIKey);
  return new AuthLink({
    url: GRAPHQL_URI,
    region: APP_AWS_REGION,
    auth,
  });
});
