import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    height: 100%;
    box-sizing: border-box;
    background-color: ${({ theme, primaryBackground }: any) => (primaryBackground ? theme.primary : theme.background)}
    *{
      transition: ${({ theme }: any) => theme.transition}
    }
  }
  body {
    padding: 0;
    height: 100%;
    margin: 0;
    background-color: ${({ theme, primaryBackground }: any) => (primaryBackground ? theme.primary : theme.background)};
    font-family: ${({ theme: { font } }: any) => font.family.join(' ')},system-ui,BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,icomoon;
  }
  
  
  @supports (-webkit-touch-callout: none) {
     select, input, textarea {
      font-size: 16px!important;
    }
  }

  @supports not (-webkit-touch-callout: none) {
     select, input, textarea {
      font-size: inherit;
    }
  }
  
  
  #__next{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

export default GlobalStyle;


export const GlobalWrapper = styled.div`
  position: relative;
  background-color: ${({ $isTransparent, theme }:any) => ($isTransparent ? 'transparent' : theme.background)};
  padding: 20px 30px;
`;

export const MessageStyled = styled.div`
  display:block;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-justify: inter-word;
`;

export const AnimateWrap = styled.div`
 ${({ isShow }: any) => `
    padding-top: 1.5rem;
    display: block;
    height: auto;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
    ${isShow && `
      max-height: 600px;
      opacity: 1;
    `};
  `}
`;

const scaleYON = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  1% {
    visibility: visible;
  }

  80% {
    opacity: 1;
  }

  100% {
    max-height: 600px;
  }
`;

const scaleYOFF = keyframes`
  0% {
    max-height: 600px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const AnimateFrameWrap = styled.div`
  ${({ isShow }: any) => css`
    display: block;
    animation: ${isShow ? scaleYON : scaleYOFF};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  `}
`;

export const XButton = styled.div`
  ${({ theme: { shades }, $top = 0, $right = 0 }: any) => css`
    position: absolute;
    right: ${$right || '35px'};
    top: ${$top}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    font-size: 6px;
    color: ${shades[3]};

    & {

      ::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '';
        width: 12px;
        height: 3px;
        background-color: ${shades[3]};
        border-radius: 3px;
        transform: rotate(-45deg);
      }

      ::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '';
        width: 12px;
        height: 3px;
        background-color: ${shades[3]};
        border-radius: 3px;
        transform: rotate(45deg);
      }

      :active {

        &::after {
          background-color: ${shades[4]};
        }

        &::before {
          background-color: ${shades[4]};
        }
      }
    }
  `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: block;
  margint: auto;
  padding: 1rem;
`;

export const DisplayContianer = styled.div`
  ${({ theme: { primary } }: any) => `
    color: ${primary};
    display: flex;
    margin: 1rem 0;
    height: 30px;
    align-items: center;

    & i {
      margin: 0 1rem 0 0;
    }
  `}
`;

export const TextContainer = styled.div`
  ${({ theme: { shades } }: any) => `
    color: ${shades[4]};
    width: 100%;
    display: flex;
    justify-content: space-between;

    & i {
      margin: 0;
    }
  `}
`;

export const SpanLink = styled.span`
  ${({ theme: { secondary } }: any) => `
    display: block;
    color: ${secondary};
    text-decoration: underline;
  `}
`;
