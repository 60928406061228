import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { GOOGLE_MANAGER_TAG_ID } = publicRuntimeConfig;

const tagManagerArgs = {
  app: 'WGV',
  plugins: [
    googleTagManager({
      containerId: GOOGLE_MANAGER_TAG_ID || '',
    }),
  ],
};

let analytics: any = null;

export default {
  initialize: () => {
    analytics = Analytics(tagManagerArgs);
  },
  dataLayer: (page: string) => {
    analytics.page(page);
  },
};
