import { getUPFURI } from "./getUpf";
import { isSocial } from "./isSocial";

const centreUPF = (auth: any) => {
  if (auth) {
    const localUPF = isSocial(auth) ? getUPFURI(auth.attributes.sub) : auth.username ? getUPFURI(auth.username) : ''
    return localUPF;
  } else {
    return ''
  }
  
};

export default centreUPF
