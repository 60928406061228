import debug from 'debug';
import Sentry from './sentry';

let env = 'Server';
if (typeof window !== 'undefined') {
  env = 'Browser';
}

const debugError = debug(`${env}:error`);
const debugLog = debug(`${env}:log`);

debug.enable('Server:*,Browser:*');

// set this namespace to log via console.log
// eslint-disable-next-line no-console
debugLog.log = console.log.bind(console);

type EventType = {
  setUser: (id: string | number, email: string, name: string)=>void
};

export const error = (...args:any) => {
  const originalFunc = debugError.extend(args);

  /**
   * @description error function with bugsnag callback
   * @param breadcrumb: string (function name| class name)
   * @param e: error
   * @param callback: https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
   */
  return (breadcrumb:string, e:any, callback?: ((event: EventType)=>any) | undefined) => {
    Sentry.captureException(e);
    return originalFunc.apply(null, [breadcrumb, e]);
  };
};

export default (...args:any) => ({
  error: error(args),
  log: debugLog.extend(args),
});
