const social = {
  addStack: async (key: string, value: any) => {
    await localStorage.setItem(key, value);
  },
  getStack: async (key: string) => {
    const result = await localStorage.getItem(key);
    return result;
  },
  removeStack: async (key: string) => {
    await localStorage.removeItem(key);
  },
};

export default social;
