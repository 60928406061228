import React, { ReactNode } from 'react';
import StyledButton from './button.style';

interface ButtonProps {
  id?: string
  theme?: any
  /**
   * React children node can be string
   */
  children?: ReactNode
  type?: 'primary' | 'secondary' | 'text' | 'default';
  loading?: boolean
  disabled?: boolean
  /**
   * Size of button large | small
   * @default medium
   */
  size?: string
  /**
   * Handle click on passing onClick function
   */
  onClick?: (evt: any) => void
  withBorder?: boolean
}

/**
 * Button component
 */
export const Button = ({
  onClick,
  loading,
  type,
  children,
  size,
  disabled,
  withBorder,
  ...rest
}: ButtonProps) => (
  <StyledButton
    onClick={!loading ? onClick : () => {}}
    button={type}
    size={size}
    isLoading={loading}
    inActive={disabled}
    withBorder={withBorder}
    type="button"
    {...rest}
  >
    {loading && <i className="icon-spinner" />}
    {children}
  </StyledButton>
);

Button.defaultProps = {
  type: 'primary',
  loading: false,
  disabled: false,
};

export default Button;
