import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const {
  APP_AWS_REGION = '',
  APP_AWS_USER_POOLS_ID = '',
  APP_AWS_USER_POOLS_WEB_CLIENT_ID = '',
  GRAPHQL_URI = '',
  // APP_AWS_IDENTITY_POOL_ID = '',
  SITE_URL = '',
  OAUTH_DOMAIN = '',
} = publicRuntimeConfig;

export default {
  API: {
    endpoints: [
      {
        name: 'campaigns',
        endpoint: GRAPHQL_URI,
        region: APP_AWS_REGION,
      },
    ],
  },
  Auth: {
    mandatorySignIn: true,
    region: APP_AWS_REGION,
    userPoolId: APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    // identityPoolId: APP_AWS_IDENTITY_POOL_ID,
    oauth: {
      domain: OAUTH_DOMAIN,
      scope: [
        'email',
        'phone',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: SITE_URL,
      redirectSignOut: SITE_URL,
      responseType: 'code',
    },
  },
  federationTarget: 'COGNITO_USER_POOLS',
};
