export const BASE_COLORS = {
  primary: '#312783',
  secondary: '#00BFDF',
  danger: '#ED3B56',
  orange: '#F49D3F',
  green: '#E2E449',
  skyblue:'#03a9fc',
  transition: '180ms ease',
  shades: [
    '#ffffff', // 0
    '#F4F6FC', // 1
    '#E0E0EF', // 2
    '#8798AD', // 3
    '#2E384D', // 4
    '#000000', // 5
    '#1B68E2', // 6
    '#1DBADF', // 7
    '#7A7B99', // 8
    '#4C4C4C', //9
    '#5188DE',  //10
    '#3f3f41',  //11
    '#4cad2b',  //12
    '#E0E0EF', //13
  ],
};

const fontWeight = {
  light: 200,
  normal: 400,
  bold: 700,
};

const fontSizes = {
  text: {
    sm: '12px', md: '14px', lg: '16px',
  },
  heading: {
    sm: '22px', md: '30px', lg: '40px',
  },
};

const font = {
  weight: fontWeight,
  size: fontSizes,
  family: ['Montserrat'],
};

const boxShadow = {
  xs: '0 2px 2px 0 rgba(0,0,0,.1)',
  sm: '0 2px 4px 0 rgba(0,0,0,.1)',
  md: '0 10px 18px 0 rgba(70,40,11,.16)',
  lg: '0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08)',
};

const borderRadius = {
  sm: '2px',
  md: '4px',
  lg: '8px',
};

const breakpoints = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
};

const common = {
  ...BASE_COLORS,
  breakpoints,
  font,
  boxShadow,
  borderRadius,
};

export default {
  light: {
    name: 'Light',
    ...common,
    background: '#ffffff',
    foreground: '#d2edf7',
    text: {
      primary: '#ffffff',
      secondary: '#2E3192',
    },
  },
  dark: {
    name: 'Dark',
    ...common,
    primary: '#224669',
    secondary: '#12647d',
    background: '#282a2b',
    foreground: '#1d1e1f',
    text: {
      primary: '#ffffff',
      secondary: '#d2edf7',
    },
  },
};
