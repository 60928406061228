import styled, { keyframes } from 'styled-components';

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 0;
  overflow-x:hidden;
  overflow-y:auto;

  .the-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const HeaderWrapper = styled.div`
position: sticky;
display: flex;
flex: 0 0 auto;
box-shadow: rgba(50, 50, 50, 0.11) 0 4px 6px, rgba(0, 0, 0, 0.08) 0 1px 3px;
z-index: 99;
`;

export const FooterWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
width: 100%;
`;

const transitionAnim = keyframes`
  from {
    top: -150px;
    opacity: 0;
  }

  to {
    top: 10px;
    opacity: 1;
  }
`;

export const Confirmation = styled.div`
  display: flex;
  width: 95%;
  min-height: 84px;
  background-color: #E9F5E5;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1500;
  border-radius: 5px;
  color: #269900;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  animation: ${transitionAnim};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
`;

export const CloseConfirm = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 15px;
    height: 3px;
    background-color: #269900;
    border-radius: 3px;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 3px;
    height: 15px;
    background-color: #269900;
    border-radius: 3px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;