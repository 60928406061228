/* eslint-disable no-use-before-define */
import App from 'next/app';
import { ThemeProvider } from 'styled-components';
import dynamic from 'next/dynamic';
import { ModalProvider } from 'styled-react-modal';

import { ErrorBoundary, ErrorScreen, onError } from '../helpers/sentry';
import { ModelContainer } from '../components/modal/modal.style';
import theme from '../components/ui/theme';
import { StaticHead } from '../components/layout';
import { configureAmplify } from '../helpers/appSync';
import logger from '../helpers/logger';
import tagManager from '../helpers/tagManager';
// import isRobot from '../helpers/isRobot';

const { log } = logger('_app');

const AppProvider = dynamic(async () => {
  // log('Initializing Amplify...');
  await configureAmplify();
  // log('Amplify initialized!');
  return import('../helpers/context');
});

class WeGive extends App {
  componentDidMount() {
    tagManager.initialize();
  }

  render() {
    const {
      Component,
      pageProps,
    } = this.props;
    return (
      <ThemeProvider theme={theme.light}>
        <ErrorBoundary fallback={ErrorScreen} onError={onError}>
          <StaticHead />
          <AppProvider>
            <ModalProvider backgroundComponent={ModelContainer}>
              <Component {...pageProps} />
            </ModalProvider>
          </AppProvider>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
}

export default WeGive;
