// import * as Sentry from '@sentry/node';
// import * as Sentry from '@sentry/browser';

import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import getConfig from 'next/config';

const config = getConfig();
const publicRuntimeConfig = config?.publicRuntimeConfig;
let SentryInstance: any = null;

const {
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  RELEASE_STAGE,
  BITBUCKET_COMMIT,
} = publicRuntimeConfig || {};

if (SENTRY_DSN && !SentryInstance) {
  console.info('Initialize Sentry');
  Sentry.init({
    dsn: SENTRY_DSN,
    release: BITBUCKET_COMMIT || 'undefined',
    environment: RELEASE_STAGE,
    tracesSampleRate: SENTRY_SAMPLE_RATE || 1.0,
  });

  SentryInstance = Sentry;
} else {
  console.info('Cannot detect SENTRY_DSN');
}

export const { ErrorBoundary } = SentryInstance || Sentry;

const ErrorContainer = styled.div``;
const P = styled.p``;
const H1 = styled.h1``;
const Code = styled.code``;
const Button = styled.button``;
const Strong = styled.strong``;

export const ErrorScreen = ({ clearError }:any) => (
  <ErrorContainer>
    <H1>️ Error </H1>
    <P>
      <Strong>
        I&apos;m reare from Sentry ;).
        Uh oh, there was an error in the component tree!
      </Strong>
    </P>
    <P>
      This
      <Code>fallback</Code>
      {' '}
      prop can be used to show something useful to your users when such errors occur.
    </P>
    <Button type="button" onClick={clearError}>Reset</Button>
  </ErrorContainer>
);

export const onError = (event:any, data:any) => {
  // You can also provide an onError callback to run just on errors caught by
  // the error boundary. Maybe you want to attach some of the current state from
  // whatever model/store you're using (e.g redux)
  console.log('About to send this event', { event, data });
};

export const TestSentryLog = () => {
  if (SENTRY_DSN) {
    try {
      throw new Error('Test Sentry');
    } catch (e) {
      Sentry.setUser({
        email: 'test@mohara.co',
        id: 'xxxxxxxxx',
        isAnonymous: true,
      });
      SentryInstance.captureException(e);
    }
  }
};

export default SentryInstance;
