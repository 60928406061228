import styled, { css } from 'styled-components';

interface IHeaderProps {
  history?: boolean
  theme?: any
  logoURL?: string
}

const reuseStyle = css`
  position: absolute;
  display: block;
  content: '';
  width: 15px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 5px;
`;

export const Container = styled.div`
  ${({ theme: { primary } }: IHeaderProps) => (
    css`
      display: flex;
      padding: 0.8rem;
      align-items: center;
      justify-content: space-between;
      background-color: ${primary};
      width: 100%;
      height: 58px;
      box-sizing: border-box;
    `
  )}
`;

export const PageName = styled.div`
  ${({ theme: { shades, font } }: IHeaderProps) => css`
      color: ${shades && shades[0]};
      font-style: normal;
      font-size: ${font && font.size.text.lg};
      font-weight: ${font && font.weight.bold};
    `}
`;

export const ArrowLeft = styled.div`
  ${({ history }: IHeaderProps) => css`
    display: block;
    position: relative;
    width: 58px;
    height: 58px;
    background-color: transparent;
    ${!!history && css`
      & {
        ::before {
          ${reuseStyle};
          top: 42%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        ::after {
          ${reuseStyle};
          top: 57%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:active {
          background-color: #ffffff10;
        }
      }
    `}
  `}
`;

export const AppLogo = styled.div`
  ${({ logoURL, theme: { primary } }: IHeaderProps) => css`
    display: block;
    cursor: pointer;
    width: 38px;
    height: 38px;
    background-color: ${primary};
    ${logoURL && css`
      background-image: url(${logoURL});
      background-size: 100%;
    `}
  `}
`;
