// import Cookies from 'js-cookie';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache  } from '@apollo/client';
import QueueLink from 'apollo-link-queue';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
// import { setContext } from 'apollo-link-context';
import SerializingLink from 'apollo-link-serialize';
import { CachePersistor } from 'apollo3-cache-persist';

import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
import { authLink } from './appSync';
import { error } from './logger';

const log = error('apollo');

const SCHEMA_VERSION = '1';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const { publicRuntimeConfig } = getConfig();

const {
  GRAPHQL_URI = '',
} = publicRuntimeConfig;

const isBrowser = typeof window !== 'undefined';

let globalClient:any;

const httpLink = createHttpLink({ uri: ({ operationName }) => `${GRAPHQL_URI}?${operationName}`, fetch });

const retryLink = new RetryLink({ attempts: { max: Infinity } });

// Middleware to inject global variables in any query
const middlewareLink = new ApolloLink((operation, forward) => forward(
  Object.assign(operation, {
    variables: {
      ...operation.variables,
    },
  }),
));

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => log('graphQLErrors',
      `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation: ${JSON.stringify(
        operation.operationName,
      )}`));
  }
  if (networkError) {
    log('networkError', `[Network error]: ${networkError}`);
  }
});

const getApolloClient = async (useApiKey?: boolean) => {
  const queueLink = new QueueLink();

  if (isBrowser) {
    window.addEventListener('offline', () => queueLink.close());
    window.addEventListener('online', () => queueLink.open());
  }

  const serializingLink = new SerializingLink();

  const trackerLink = new ApolloLink((operation, forward) => {
    if (forward === undefined) return null;

    const context = operation.getContext();
    let tack:any = null;
    if (isBrowser) {
      tack = window.localStorage.getItem('trackedQueries');
    }
    const trackedQueries = JSON.parse(tack) || [];

    if (context.tracked !== undefined) {
      const { operationName, query, variables } = operation;

      const newTrackedQuery = {
        query,
        context,
        variables,
        operationName,
      };
      if (isBrowser) {
        window.localStorage.setItem('trackedQueries', JSON.stringify([...trackedQueries, newTrackedQuery]));
      }
    }

    return forward(operation).map((data) => {
      if (context.tracked !== undefined) {
        if (isBrowser) {
          window.localStorage.setItem('trackedQueries', JSON.stringify(trackedQueries));
        }
      }

      return data;
    });
  });
  const auth = await authLink(useApiKey);
  const link = ApolloLink.from([
    middlewareLink,
    trackerLink,
    queueLink,
    // @ts-ignore
    serializingLink,
    retryLink,
    errorLink,
    auth,
    httpLink,
  ]);

  const cache = new InMemoryCache();
  let storage:any = {
    setItem: () => {},
    getItem: () => {},
  };

  if (isBrowser) {
    storage = window.localStorage;
    const persistor = new CachePersistor({
      cache,
      storage,
    });

    const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      await persistor.restore();
    } else {
      await persistor.purge();
      window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  }

  if (globalClient && isBrowser) {
    return globalClient;
  }

  const client = new ApolloClient({
    link,
    cache,
    ssrMode: true,
  });
  globalClient = client;

  return client;
};

export default getApolloClient;
