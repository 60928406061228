import styled from "styled-components";
import Modal from "styled-react-modal";
export const ModelContainer = styled.div`
  ${({ theme: { shades } }: any) => `
background-color:${shades[5]}50;
display: block;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding: 1rem;
z-index: 99;
overflow: auto;
transition: all 0.3s ease-in-out;
transition-duration: 5s;
`}
`;
export const ModalPanel = Modal.styled`
  ${({ theme: { primary}}: any) =>`
width: 250px;
height:0px;
display: block;
position: absolute;
top: 87%;
left: 50%;
transform: translate(-50%, -50%);
 box-sizing: border-box;
 border-radius: 150px 150px 0px 0px;
color:${primary};
background-color: #E0E0EF;
text-align: center;
opacity: 0;
  `}
  @media (max-height:750px){
    top: 84%;
  }
  @media (min-width: 1200px) {
    left:47%;
    top:84%;
  }
`;
