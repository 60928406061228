import styled from 'styled-components';
import Button from '../button';

export const Wrapper = styled.div`
background: ${({ theme: { shades } }:any) => shades[1]};
padding: 20px;
position: absolute;
z-index: 9999;
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
&:after {
  content: "";
  clear: both;
  display: table;
}
`;

export const Text = styled.p`
color: ${({ theme: { primary } }:any) => primary};
margin-top: 0;
margin-bottom: 20px;
text-align: center;
font-weight: ${({ theme: { font: { weight } } }:any) => weight.light};
`;

export const AcceptButton = styled(Button)`
float: right;
display: inline-block;
max-width: 160px;
`;

export const LearnMoreLink = styled.a`
color: ${({ theme: { secondary } }:any) => secondary};
font-weight: bold;
display: inline-block;
margin-top: 18px;
width: 100%;
max-width: 160px;
text-align: center;
text-decoration: underline;
cursor: pointer;
`;
