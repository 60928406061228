import styled, { css, keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const primaryColorStyle = (primary: string, shades: any, isLoading: boolean, withBorder: boolean) => css`
  border: 1px solid ${withBorder ? shades[0] : primary};
  background-color: ${isLoading ? shades[3] : primary};
  color: ${shades[0]};
`;

const secondaryColorStyle = (primary: string) => css`
  border: 1px solid ${primary};
  background-color: transparent;
  color: ${primary};
`;

const textColorStyle = (primary: string) => css`
  border: none;
  background-color: transparent;
  color: ${primary};
`;

const conditionColorPattern = (
  button: string,
  theme: any,
  isLoading: boolean,
  withBorder: boolean,
) => {
  const { primary, shades, text } = theme;
  switch (button) {
    case 'primary': return primaryColorStyle(primary, shades, isLoading, withBorder);
    case 'secondary': return secondaryColorStyle(text.primary);
    case 'text': return textColorStyle(primary);
    default: return secondaryColorStyle(primary);
  }
};

const StyledButton = styled.button`
  ${({
    inActive, button, size, isLoading, theme: {
      fontSize, shades, boxShadow,
    }, theme, withBorder,
  }: any) => (
    css`
        position: relative;
        ${conditionColorPattern(button, theme, isLoading, withBorder)};
        font-weight: 700;

        ${isLoading && `
          pointer-events:none;
        `};

        height: 44px;
        outline: none;
        width: 100%;

        font-size: 14px;
        line-height: 18px;
        transition: all 0.2s cubic-bezier(0.83, 0, 0.17, 1);
        ${inActive && css`
          background-color: ${shades[1]};
          color: ${shades[2]};
          border: none;
          pointer-events:none;
          opacity: 0.6;
          box-shadow: ${boxShadow && boxShadow.sm};
        `};

        user-select: none;
        & {
          :not(:disabled) {
            cursor: ${!isLoading ? 'pointer' : 'progress'};
          }
          :active:not(:disabled) {
            opacity: 0.5;
          }

          > i {
            display: flex;
            position: absolute;
            top: 50%;
            transform: translate(0 , -50%);
            height: calc(${fontSize && fontSize.text.md} * 1.3);
            font-size: calc(${fontSize && fontSize.text.md} * 1.3);
            margin-top: calc(${fontSize && fontSize.text.md} * 1.3 / -1.9);
            left: 15px;
            ${size === 'large' ? 'left: 25px;' : ''}
            ${size === 'small' ? 'left: 10px;' : ''}

          &:before {
            margin: auto 0;
            ${isLoading && css`
              display: inline-block;
              animation: ${rotate} 0.5s linear infinite;
            `}
            }
          }
        }
      `
  )}
`;

const socialColorBtn = (type: string) => {
  const facebook = { play: 'background: linear-gradient(180deg, #6F91C9 0%, #3C5899 100%);', reverse: 'background: linear-gradient(180deg, #3C5899 100%, #6F91C9 0%);' };
  const google = { play: 'background: linear-gradient(180deg, #F1826A 0%, #DE4B39 100%);', reverse: 'background: linear-gradient(180deg, #DE4B39 100%, #F1826A 0%);' };

  switch (type) {
    case 'facebook': return facebook;
    case 'google': return google;
    default: return facebook;
  }
};

export const SocialButton = styled.button`
  ${({ theme: { shades, font }, social }: any) => `
    display: block;
    ${socialColorBtn(social).play}
    color: ${shades[0]};
    border: none;
    padding: 1rem;
    height: 44px;
    outline: none;
    width: 100%;
    font-weight: ${font.weight.normal};
    font-size: ${font.size.text.lg};
    line-height: 18px;
    margin: 1rem 0;

    &:active {
      ${socialColorBtn(social).reverse}
    }
  `}
`;

export default StyledButton;
