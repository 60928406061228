import React from 'react';
import { htmlToText } from 'html-to-text';
import Head from 'next/head';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const {
  FB_APP_ID,
} = publicRuntimeConfig;

type Props = {
  name?: string
  title?: string
  description?: string
  theme?: any
  header?: string
  url?: string
  image?: string
  showBack?: boolean
  width?: number
  height?: number
};

/**
 * @description SEO meta tags for social media
 * @param name
 * @param title
 * @param description
 * @param url
 * @param image
 * @param width
 * @param height
 * @returns {*}
 * @constructor
 */
const OpenGraph = ({
  name = 'WeGive',
  title = '',
  description = '',
  url = '',
  image = '',
  width = 580,
  height = 310,
}:Props): any => {
  const imageQuery = `w=${width}&h=${height}`;
  return (
    <Head>
      <link rel="canonical" href={url} />
      {/* Facebook tags */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={htmlToText(description)} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={name} />
      <meta property="og:image" content={`${image}?${imageQuery}`} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image:secure_url" content={`${image}?${imageQuery}`}  />
      <meta property="og:image:width" content="580" />
      <meta property="og:image:height" content="310" />
      <meta property="fb:app_id" content={FB_APP_ID} />
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={`${image}?${imageQuery}`} />
      <meta itemProp="name" content={description} />
      <meta itemProp="description" content={description} />
    </Head>
  );
 
};


export default OpenGraph;
