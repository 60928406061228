import * as React from "react";
import {useState} from "react";
import { useRouter } from "next/router";
import logger from "../../../../helpers/logger";
import {
  INavigation,
  SOCIAL_DATA_STACK,
  FOR_SOCIAL_FAVOUITE,
  ROUTE,
} from "../../../../constants";
import PopUp from "../../../modal/donationmodal";
import {
  MenuContainer,
  ButtonMenu,
  NotificationMenu,
  DonationGoalContent,
  LogDonation,
  MakeDonation,
  MiddleLine,
  Open,
  Close,
} from "./navigation.style";
import social from "../../../../helpers/socialStack";
import { getUPFURI } from "../../../../helpers/getUpf";
import centreUPF from "@/helpers/centreUPF";

interface INavProps {
  /**
   * On select page function
   */
  onRouteChange?: Function;
  navigations?: INavigation[];
  isCardExpired?: boolean;
  auth?: any;
}

const { log } = logger("ui:footer:navigation");

/**
 * Navigation component
 */
export const Navigation = (props: INavProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const {route}=router;
  const { navigations = [], onRouteChange = () => {}, auth = {} } = props;

  const ToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = async (route: string) => {
    if (route === "/log") {
      localStorage.removeItem('taxPeriod')
      ToggleOpen();
      return;
    }
    if (route !== "/payment/tax") {
      localStorage.removeItem("donationhistory");
      localStorage.removeItem('taxPeriod')
    }
    await social.removeStack(SOCIAL_DATA_STACK);
    await social.removeStack(FOR_SOCIAL_FAVOUITE);
    if (auth) {
      // console.log('auth', auth);
      const { username, attributes } = auth;
      const isGoogle = username && username.indexOf("Google") !== -1;
      const isFacebook = username && username.indexOf("Facebook") !== -1;
      const localUpf =
        isGoogle || isFacebook
          ? getUPFURI(attributes.sub)
          : getUPFURI(username);
      const upfRI = auth.username ? `${route}${localUpf}` : route;
      // console.log('upfRI', upfRI, auth);
      onRouteChange(upfRI);
    }
  };
  const upfRI = centreUPF(auth);
  const LogDonationPage = () => {
    router.push(`${ROUTE.LOG_DONATION}`);
  };
  const MakeDonate = () =>{
    router.push(`${ROUTE.MAKE_DONATION}`);
  }
  return (
    <MenuContainer>
      {navigations.map((item: INavigation, index: any) => (
        <ButtonMenu
          data-cy={`menu-${index}`}
          key={index}
          size={20}
          onClick={() => onSelect(item.main)}
          selected={item.selected}
        >
          {item?.main === "/log" ? (
            !isOpen ? (
              <Open disabled={route === "/forgottenpassword" ? true : false}>&#10011;</Open>
            ) : (
              <Close>&#10006;</Close>
            )
          ) : (
            <span  key={index} className={`${(item.selected && item.icon === "icon-home") ? item.icon+'-active':item.icon} iconmenu`} />
          )}
          {item.notify && <NotificationMenu />}
        </ButtonMenu>
      ))}

      <PopUp isOpen={isOpen} onClose={onClose}>
        <DonationGoalContent>
          <LogDonation data-cy='log-donation' onClick={LogDonationPage}>
            Log <br />
            Donation
          </LogDonation>
          <MiddleLine></MiddleLine>
          <MakeDonation data-cy='make-donation' onClick={MakeDonate}>
            Make <br />
            Donation
          </MakeDonation>
        </DonationGoalContent>
      </PopUp>
    </MenuContainer>
  );
};

export default Navigation;
