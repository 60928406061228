import Head from "next/head";
import { useRouter } from "next/router";
import getConfig from "next/config";
import React, { useEffect } from "react";
import GlobalStyle from "../global.style";
import { ContentWrapper, HeaderWrapper } from "./layout.style";
import Header from "../ui/header";
import FooterNav from "../ui/footer/navigation";
import Cookie from "../ui/cookie";
import {
  NAVIGATIONS,
  ROUTE,
  SOCIAL_DATA_STACK,
  FOR_SOCIAL_FAVOUITE,
} from "@/constants";

import OpenGraph from "../openGraph";
import social from "../../helpers/socialStack";
import { REFERRER_VAL } from "@/helpers/context";
import { getUPFURI } from "@/helpers/getUpf";
import centreUPF from "@/helpers/centreUPF";

const { publicRuntimeConfig } = getConfig();
const desWebsite =
  "WeGive Ventures is a social enterprise that aims to maximise charitable giving. No matter how large or small your donation, our app ensures that donations can be made easily to those charities who need it the most.";

const { COOKIE_LEARN_MORE } = publicRuntimeConfig;

type Props = {
  title?: string;
  description?: string;
  theme?: any;
  header?: string;
  showBack?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  url?: string;
  image?: string;
  name?: string;
  notify?: boolean;
  primaryBackground?: boolean;
  logoURL?: string;
  children?: any;
  auth?: any;
};

export const StaticHead = () => (
  <Head>
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="stylesheet" href="/fonts/style.css" />
    <link rel="stylesheet" href="/icons/style.css" />
    <link rel="shortcut icon" href="/logo/logo-32.png" />
    <link rel="icon" href="/logo/logo-32.png" sizes="32x32" />
    <link rel="icon" href="/logo/logo-192.png" sizes="192x192" />
    <link rel="apple-touch-icon-precomposed" href="/logo/logo-180.png" />
    <link rel="apple-touch-icon" href="/logo/logo-192.png" />
    <link rel="manifest" href="/manifest.json" />
  </Head>
);

export default ({
  children,
  title = "Home | WeGive",
  description = desWebsite,
  image = "/logo/logo-192.png",
  header = "Home",
  showBack = false,
  showHeader = true,
  showFooter = true,
  logoURL = "/logo/logo-192.png",
  primaryBackground = false,
  notify = false,
  auth = {},
}: Props) => {
  const router = useRouter();
  const { upf, amount, firstname } = router.query;
  const { asPath } = router;
  const onRouteChange = (route: string) => {
    router.push(route);
  };

  const getNavigation = () =>
    NAVIGATIONS.map((nav) => {
      const { main, sub } = nav;
      const { pathname } = router;
      sub.push(main);
      if (sub.indexOf(pathname) > -1) {
        return {
          ...nav,
          selected: true,
          notify: ROUTE.PROFILE_USER.DEFAULT === nav.main && notify,
        };
      }
      return {
        ...nav,
        selected: false,
        notify: ROUTE.PROFILE_USER.DEFAULT === nav.main && notify,
      };
    });

  const setupParams = async () => {
    // const sourceRefer = await localStorage.getItem(REFERRER_VAL)
    // if (sourceRefer) return
    if (upf && upf.length !== 0) {
      if (amount) {
        await localStorage.setItem(
          REFERRER_VAL,
          JSON.stringify({ upf, amount, firstname, asPath })
        );
      } else {
        const isNotEmpty = await localStorage.getItem(REFERRER_VAL);
        if (!isNotEmpty) {
          await localStorage.setItem(REFERRER_VAL, JSON.stringify({ asPath }));
        }
      }
    }
  };

  useEffect(() => {
    setupParams();
  }, []);
  const upfRI = centreUPF(auth);

  const backButtonAction = async () => {
    await social.removeStack(SOCIAL_DATA_STACK);
    await social.removeStack(FOR_SOCIAL_FAVOUITE);
    const stackString = await social.getStack(SOCIAL_DATA_STACK);
    if (stackString) return;
    if (showBack) {
      router.back();
    }
  };

  const navigateHome = () => {
    router.push(ROUTE.HOME);
  };

  // console.log(`upf: ${upf}, amount: ${amount}, firstname: ${firstname}`);
  // const renderTitle = () => (upf && amount && firstname) ? `${firstname} has donated £${amount} to ${title.split('Make Donation |')[1]}` : title;

  return (
    <>
      <GlobalStyle primaryBackground={primaryBackground} />
      <OpenGraph title={title} description={description} image={image} />
      {showHeader ? (
        <HeaderWrapper>
          <Header
            logoURL={logoURL}
            pageName={header}
            history={showBack}
            onClickArrow={backButtonAction}
            onClickLogo={navigateHome}
          />
        </HeaderWrapper>
      ) : (
        ""
      )}
      {showHeader ? (
        <Cookie
          learnMoreLink={COOKIE_LEARN_MORE || ""}
          style={{
            top: "58px",
          }}
        />
      ) : (
        ""
      )}
      <ContentWrapper>{children}</ContentWrapper>
      {showFooter ? (
        <FooterNav
          auth={auth}
          onRouteChange={onRouteChange}
          navigations={getNavigation()}
        />
      ) : null}
    </>
  );
};
