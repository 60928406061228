import * as React from 'react';
import {
  Container,
  PageName,
  ArrowLeft,
  AppLogo,
} from './header.style';

interface IHeaderProps {
  /**
   * Page title name
   * @default Wegive
   */
  pageName: string
  /**
   * Image logo
   * @default logo/32x32.png
   */
  logoURL?: any
  /**
   * History URL
   * @default /home
   */
  history?: string | boolean
  Detail?:boolean
  /**
   * Event function
   * @default () => void
   */
  onClickArrow?: Function
  /**
   * Logo link
   * @default () => void
   */
  onClickLogo?: Function

}

export const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
  const {
    pageName,
    logoURL,
    history,
    onClickArrow,
    onClickLogo,
  } = props;

  return (
    <Container>
      <ArrowLeft data-cy="back-btn" history={!!history} onClick={onClickArrow} />
      <PageName>{pageName}</PageName>
      <AppLogo logoURL={logoURL} onClick={onClickLogo} />
    </Container>
  );
};

export default Header;
