import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const ModelContainer = styled.div`
  ${({ theme: { shades } }: any) => `
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${shades[5]}90;
    padding: 1rem;
   z-index: 9999;
    overflow: auto;
  `}
`;

export const CloseButton = styled.div`
 ${({ theme: { primary, shades } }: any) => `
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  & {
    ::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      content: '';
      display: block;
      width: 30px;
      height: 5px;
      border-radius: 5px;
      background: ${primary};
    }

    ::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      content: '';
      display: block;
      width: 30px;
      height: 5px;
      border-radius: 5px;
      background: ${primary};
    }

    :active {
      & {
        ::before, ::after {
          background: ${shades[3]};
        }
      }
    }
  }
 `}
`;

export const ModalPanel = Modal.styled`
  ${({ theme: { shades } }: any) => `
    background-color: ${shades[0]};
    padding: 1.7rem;
    box-sizing: border-box;
  `}
`;

export const JustgivingModalPanel=Modal.styled`
${({ theme: { shades } }: any) => `
display: block;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${shades[0]};
padding: 1.7rem;
box-sizing: border-box;
`}`;

export const JustgivingModalcontent=Modal.styled`
${({ theme: { shades } }: any) => `
display: block;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${shades[0]};
padding: 1.7rem;
box-sizing: border-box;
`}`;
