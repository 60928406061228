import React from "react";
import { ModalPanel, ModelContainer } from "./doantionmodal.style";
import { ModalProvider } from "styled-react-modal";
export const PopUp: React.FunctionComponent<any> = ({
  children,
  isOpen = true,
  isLogDonation,
}: any) => {

  return (
    <>
      <ModalProvider backgroundComponent={ModelContainer}>
        <ModalPanel
          className='grow-anim'
          style={{ backgroundColor: "none" }}
          isLogDonation={isLogDonation}
          isOpen={isOpen}
        >
          {children}
        </ModalPanel>
      </ModalProvider>
    </>
  );
};
export default PopUp;
